// eslint-disable-next-line no-redeclare,no-unused-vars
function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isNumber(value) {
  return typeof value == 'number';
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isDecimal(value) {
  // TODO: Could be replaced with Number.isInteger
  if (typeof value == 'number' && value % 1 != 0) return true;
  else return false;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isString(value) {
  return typeof value == 'string';
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isArray(value) {
  return Array.isArray(value);
}

// TODO: Only used once in buildField.js, maybe could be refactored there as a const
// eslint-disable-next-line no-redeclare,no-unused-vars
function isWord(str) {
  if (isString(str) && str.match(/^[a-z0-9]+$/i)) return true;
  else return false;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isEmpty(value) {
  if (!value && value !== 0) return true;
  else if (Array.isArray(value) && !value.length) return true;
  else return false;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isset(value) {
  return value !== undefined && value !== null;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isPlainObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isEmptyObject(obj) {
  if (obj && Object.keys(obj).length === 0 && obj.constructor === Object)
    return true;
  else return false;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function isFunction(obj) {
  return typeof obj == 'function';
}
